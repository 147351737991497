<template>
    <div class="box-body">
        <div class="zhuyi-box">
            <span>* 注意：购买充值会员后，不能与 "优惠券" 及 "设备会员" 同时使用。</span>
        </div>
        <div>
            <el-button type="primary" size="large" plain style="width:140px" @click="addEvent">新增</el-button>
        </div>
        <div class="table-box">
            <el-form ref="tableData" :model="tableData" :rules="ruleData">
                <el-form-item>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable" :data="tableData"
                        style="width: 100%" min-height="900" border v-loading="loading"
                        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                        @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="index" width="65" label="序号" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width" :min-width="mytabletitle.minwidth">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="
                                mytabletitle.prop === 'name' ||
                                mytabletitle.prop === 'money' ||
                                mytabletitle.prop === 'agent_money' ||
                                mytabletitle.prop === 'shop_money' ||
                                mytabletitle.prop === 'remark' ||
                                mytabletitle.prop === 'agent_apply_type' ||
                                mytabletitle.prop === 'shop_apply_type'
                            ">
                                <div v-if="this.tableData.length > 0">
                                    <!-- 名称 -->
                                    <div v-if="mytabletitle.prop === 'name'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.name'" :rules="ruleData.name">
                                            <el-input v-model="scope.row.name" placeholder="请输入..." clearable
                                                maxlength="50" />
                                        </el-form-item>
                                    </div>
                                    <!-- 充值金额 -->
                                    <div v-if="mytabletitle.prop === 'money'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.money'" :rules="ruleData.money">
                                            <el-input v-model="scope.row.money" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 代理商分佣金额 -->
                                    <div v-if="mytabletitle.prop === 'agent_money'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.agent_money'"
                                            :rules="ruleData.agent_money">
                                            <el-input v-model="scope.row.agent_money" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 商户分佣金额 -->
                                    <div v-if="mytabletitle.prop === 'shop_money'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.shop_money'" :rules="ruleData.shop_money">
                                            <el-input v-model="scope.row.shop_money" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 赠送礼品 -->
                                    <div v-if="mytabletitle.prop === 'remark'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.remark'" :rules="ruleData.remark">
                                            <el-input v-model="scope.row.remark" placeholder="请输入..." clearable
                                                maxlength="200" />
                                        </el-form-item>
                                    </div>
                                    <!-- 代理商结算方式 -->
                                    <div v-if="mytabletitle.prop === 'agent_apply_type'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.agent_apply_type'"
                                            :rules="ruleData.agent_apply_type">
                                            <el-select v-model="scope.row.agent_apply_type" placeholder="请选择">
                                                <el-option label="T+1 结算" :value="1"></el-option>
                                                <el-option label="定期结算" :value="2"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <!-- 商户结算方式 -->
                                    <div v-if="mytabletitle.prop === 'shop_apply_type'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.shop_apply_type'"
                                            :rules="ruleData.shop_apply_type">
                                            <el-select v-model="scope.row.shop_apply_type" placeholder="请选择">
                                                <el-option label="T+1 结算" :value="1"></el-option>
                                                <el-option label="定期结算" :value="2"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
                            prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-合同列表-->
                                <div>
                                    <el-button type="danger" size="mini" @click="delClick(scope.$index)">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="end-botton">
            <el-button type="primary" size="large" style="width:200px" :disabled="buttondisable"
                @click="seveEvent('tableData')">保存</el-button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
    name: "rechargeMemberDefSet",
    inject: ["reload"], //刷新引用
    data() {
        // 金额效验（可小数）
        var checkInputEvent = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }

            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error("请输入数字"));
                } else {
                    let result = value.toString().indexOf(".");
                    if (result != -1) {
                        let getdata = value.toString().split(".");
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error("请保留小数点后两位"));
                        }
                    }

                    if (value < 0) {
                        callback(new Error("值需大于0"));
                    } else {
                        callback();
                    }
                }
            }, 500);
        };
        return {

            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreehandle: 80,
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: "name",
                    label: "名称",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "money",
                    label: "充值金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "agent_money",
                    label: "代理商分佣金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "shop_money",
                    label: "商户分佣金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "remark",
                    label: "赠送礼品",
                    minwidth: "200",
                },
                {
                    fixedstatu: false,
                    prop: "agent_apply_type",
                    label: "代理商结算方式",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "shop_apply_type",
                    label: "商户结算方式",
                    minwidth: "150",
                },
            ], // 数据列表中-数据表头
            tableData: [],
            ruleData: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "change",
                    },
                ],
                money: [
                    {
                        required: true,
                        message: "充值金额不能为空",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: "change",
                    }
                ],
                agent_money: [
                    {
                        required: true,
                        message: "代理商分佣金额不能为空",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: "change",
                    }
                ],
                shop_money: [
                    {
                        required: true,
                        message: "商户分佣金额不能为空",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: "change",
                    }
                ],
                remark: [
                    {
                        required: true,
                        message: "赠送礼品不能为空",
                        trigger: "change",
                    },
                ],
                agent_apply_type: [
                    {
                        required: true,
                        message: "代理商结算方式不能为空",
                        trigger: "change",
                    },
                ],
                shop_apply_type: [
                    {
                        required: true,
                        message: "商户结算方式不能为空",
                        trigger: "change",
                    },
                ]
            },

            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            buttondisable: false,

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        };
    },
    methods: {
        // 新增
        addEvent() {
            let data = {
                type: 1,
                name: '',
                money: '',
                agent_money: '',
                shop_money: '',
                remark: '',
                agent_apply_type: '',
                shop_apply_type: '',
            };
            this.tableData.push(data);
        },

        // 删除
        delClick(index) {
            this.tableData.splice(index, 1);
        },

        // 保存
        seveEvent(formName) {
            this.buttondisable = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let tabledata = this.tableData
                    tabledata.forEach(element => {
                        if (element.money) {
                            element.money = element.money * 100;
                        }
                        if (element.agent_money) {
                            element.agent_money = element.agent_money * 100;
                        }
                        if (element.shop_money) {
                            element.shop_money = element.shop_money * 100;
                        }
                    });

                    axios
                        .post(
                            "/admin/printWallet/setUpdate",
                            {
                                type: 1,
                                data: tabledata,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: "success",
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.buttondisable = false;
                                        this.reload(); //刷新
                                    },
                                });
                            } else {
                                setTimeout(() => {
                                    this.buttondisable = false;
                                    this.reload(); //刷新
                                }, 1000);
                            }
                        })
                        .catch(function (error) {
                            ElMessage({
                                type: "warning",
                                message: error,
                                duration: 1000,
                                onClose: () => {
                                    this.buttondisable = false;
                                    this.reload(); //刷新
                                },
                            });
                        });
                } else {
                    this.buttondisable = false;
                    return false;
                }
            });
        },

        // 获取列表信息
        getInfoEvent() {
            axios
                .post(
                    "/admin/printWallet/setIndex",
                    {
                        type: 1,
                        page: 1,
                        page_size: 10000,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        if (response.data.result.data && response.data.result.data.length > 0) {
                            let datas = response.data.result.data;
                            datas.forEach(element => {
                                if (element.money) {
                                    element.money = (element.money / 100).toFixed(2);
                                }
                                if (element.agent_money) {
                                    element.agent_money = (element.agent_money / 100).toFixed(2);
                                }
                                if (element.shop_money) {
                                    element.shop_money = (element.shop_money / 100).toFixed(2);
                                }
                            });
                            this.tableData = datas;
                        }
                    }
                })
                .catch(function (error) {
                    ElMessage({
                        type: "warning",
                        message: error,
                    });
                });
        }

    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            //    
            this.getInfoEvent();

        }
    },
    components: {
    },
};
</script>
  
<style scoped>
.box-body {
    font-size: 14px;
}

.zhuyi-box {
    margin: 0 0 20px 0;
    color: var(--el-color-warning);
}

.table-box {
    margin: 20px 0;
}

.end-botton {
    text-align: center;
    margin: 20px 0;
}

.kong-class {
    margin: 20px 0;
}
</style>