<template>
    <div>
        <el-select v-model="modelvalue" filterable remote :placeholder="placeholder" :remote-method="remoteEvent"
            :disabled="disabled" @change="changeEvent" :style="style" clearable @clear="clearEvent">
            <el-option v-for="(item, index) in options" :key="index" :label="item[this.setchart.label]"
                :value="item[this.setchart.value]" />
        </el-select>
    </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
    name: "selectFullBox",
    inject: ["reload"], //刷新引用
    props: ['Placeholder', 'AxiosUrl', 'Type', 'SetChart', 'Style', 'ModelValue', 'Disabled'],
    data() {
        return {
            modelvalue: '',
            options: '',
            style: '',
            disabled: '',
            type: '',
            setchart: '',
            axiosurl: '',

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        };
    },
    watch: {
        Placeholder: {
            handler(newVal) {
                this.placeholder = newVal;
            },
            deep: true,
            immediate: true,
        },
        AxiosUrl: {
            handler(newVal) {
                this.axiosurl = newVal;
            },
            deep: true,
            immediate: true,
        },
        Type: {
            handler(newVal) {
                this.type = newVal;
            },
            deep: true,
            immediate: true,
        },
        SetChart: {
            handler(newVal) {
                this.setchart = newVal;
            },
            deep: true,
            immediate: true,
        },
        Style: {
            handler(newVal) {
                this.style = newVal;
            },
            deep: true,
            immediate: true,
        },
        ModelValue: {
            handler(newVal) {
                this.modelvalue = newVal;
            },
            deep: true,
            immediate: true,
        },
        Disabled: {
            handler(newVal) {
                this.disabled = newVal;
            },
            deep: true,
            immediate: true,
        },

    },
    methods: {
        // 匹配下拉
        remoteEvent(data) {
            axios
                .post(this.axiosurl,
                    {
                        type: this.type,
                        content: data,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.options = response.data.result.data;
                })
                .catch(function (error) {
                    ElMessage({
                        type: "warning",
                        message: error,
                    });
                });
        },

        // 选中
        changeEvent(data) {
            this.$emit('changeValue', data);
        },

        // 清空
        clearEvent() {
            this.modelvalue = '';
        }
    },
    created() {
    },
    components: {

    },
};
</script>
  
<style scoped>

</style>