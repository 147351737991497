<template>
    <div class="box-body">
        <div class="zhuyi-box">
            <span>* 注意：购买充值会员后，不能与 "优惠券" 及 "设备会员" 同时使用。</span>
        </div>
        <!-- 搜索&添加 -->
        <div class="button-box">
            <div class="button-box" style="justify-content: left;">
                <div style="margin-right: 20px;">
                    代理商:
                </div>
                <div style="margin-right: 20px;">
                    <selectFullBox ref="selectfullbox" :Placeholder="'请输入代理商名称'"
                        :AxiosUrl="'/admin/printWallet/clientOrShopInfo'" :Type="'1'"
                        :SetChart="{ 'label': 'name', 'value': 'id' }" :Style="{ 'width': '300px' }"
                        v-on:changeValue="changeValueEvent" />
                </div>
                <div style="margin-right: 20px;">
                    <el-button type="primary" @click="findEvent(this.changedata)">查询</el-button>
                </div>
                <div>
                    <el-button type="primary" plain @click="clearEvent()">重置</el-button>
                </div>
            </div>
            <div>
                <el-button type="primary" size="large" plain style="width:140px" @click="tanEvent('add', -1)">添加
                </el-button>
            </div>
        </div>
        <div class="table-box">
            <!--table表单-->
            <el-table ref="multipleTable" :header-cell-style="{ background: '#F5F7FA' }" :data="tableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
            )" style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
                @selection-change="handleSelectionChange" @cell-click="handleOneData">
                <!--多选框-用于用户选择性导出数据-->
                <el-table-column type="index" width="65" label="序号" />
                <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                    :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                    :width="mytabletitle.width" :min-width="mytabletitle.minwidth">
                    <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                    <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                    <template #default="scope" v-if="
                        mytabletitle.prop === 'shop_name' ||
                        mytabletitle.prop === 'name' ||
                        mytabletitle.prop === 'money' ||
                        mytabletitle.prop === 'agent_money' ||
                        mytabletitle.prop === 'shop_money' ||
                        mytabletitle.prop === 'remark' ||
                        mytabletitle.prop === 'agent_apply_type' ||
                        mytabletitle.prop === 'shop_apply_type'
                    ">
                        <div v-if="this.tableData.length > 0">
                            <!-- 代理商 -->
                            <div v-if="mytabletitle.prop === 'shop_name'" class="kong-class">
                                {{ scope.row[0].shop_name }}
                            </div>
                            <div v-for="(item, index) in scope.row" :key="index">
                                <!-- 名称 -->
                                <div v-if="mytabletitle.prop === 'name'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <!-- 充值金额 -->
                                <div v-if="mytabletitle.prop === 'money'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.money }}
                                    </div>
                                </div>
                                <!-- 代理商分佣金额 -->
                                <div v-if="mytabletitle.prop === 'agent_money'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.agent_money }}
                                    </div>
                                </div>
                                <!-- 商户分佣金额 -->
                                <div v-if="mytabletitle.prop === 'shop_money'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.shop_money }}
                                    </div>
                                </div>
                                <!-- 赠送礼品 -->
                                <div v-if="mytabletitle.prop === 'remark'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.remark }}
                                    </div>
                                </div>
                                <!-- 代理商结算方式 -->
                                <div v-if="mytabletitle.prop === 'agent_apply_type'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.agent_apply_type == 1 ? 'T+1 结算' : '定期结算' }}
                                    </div>
                                </div>
                                <!-- 商户结算方式 -->
                                <div v-if="mytabletitle.prop === 'shop_apply_type'" class="kong-class">
                                    <div class="input-box">
                                        {{ item.shop_apply_type == 1 ? 'T+1 结算' : '定期结算' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <!--操作&按钮-->
                <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
                    prop="myoperation" id="operation">
                    <template #default="scope">
                        <!--操作：按照页面区分，且每个按钮对应相关函数-->
                        <!--操作-合同列表-->
                        <div>
                            <el-button type="primary" size="mini" @click="tanEvent('edit', scope.row)">编辑</el-button>
                            <el-button type="danger" size="mini" @click="delClick(scope.row)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页器-->
            <div class="block" style="text-align: center; margin: 30px 0">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                </el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <tanMemberAddEdit :DialogVisible="this.dialogVisible" :Deploy="this.deploy" :Flag="this.flag" :Type="this.type"
            v-on:istruedialog="istruedialogEvent" />

    </div>
</template>

<script>
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
    name: "agentAloneSet",
    inject: ["reload"], //刷新引用
    data() {
        return {
            changedata: '',

            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreehandle: 120,
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: "shop_name",
                    label: "代理商",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "name",
                    label: "名称",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "money",
                    label: "充值金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "agent_money",
                    label: "代理商分佣金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "shop_money",
                    label: "商户分佣金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "remark",
                    label: "赠送礼品",
                    minwidth: "200",
                },
                {
                    fixedstatu: false,
                    prop: "agent_apply_type",
                    label: "代理商结算方式",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "shop_apply_type",
                    label: "商户结算方式",
                    minwidth: "150",
                },
            ], // 数据列表中-数据表头
            tableData: [],

            totalCount: 1, //数据列表中-数据总条数
            currentPage: 1,
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            pageSizes: [20, 50, 100],
            PageSize: 20,

            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            buttondisable: false,

            // 处理弹窗
            dialogVisible: false, //弹窗显示&隐藏
            // 弹窗-配置
            deploy: {
                title: '添加',
                search: {
                    label: '代理商:',
                    placeholder: '请输入代理商名称',
                    axiosurl: '/admin/printWallet/clientOrShopInfo',
                    type: '1',
                    setchart: { 'label': 'name', 'value': 'id' },
                    style: { 'width': '300px' },
                    disabled: false,
                },
                addbutton: '添加充值会员',
            },
            // 弹窗-标识 -1添加 非-1编辑
            flag: '',
            // 弹窗-tab标识来源 2代理商 3设备商
            type: '',

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    methods: {
        // 获取select接口选中的数据
        changeValueEvent(data) {
            this.changedata = data;
        },
        // 查询-代理商
        findEvent(data) {
            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/admin/printWallet/setIndex",
                    {
                        type: 2,
                        content: data,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result;
                    getdatas.data.forEach(element => {
                        if (element.money) {
                            element.money = (element.money / 100).toFixed(2);
                        }
                        if (element.agent_money) {
                            element.agent_money = (element.agent_money / 100).toFixed(2);
                        }
                        if (element.shop_money) {
                            element.shop_money = (element.shop_money / 100).toFixed(2);
                        }
                    });
                    this.myaxiosfunc(getdatas);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        // 重置-代理商
        clearEvent() {
            // 重置输入框
            this.$refs['selectfullbox'].clearEvent();
            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/admin/printWallet/setIndex",
                    {
                        type: 2,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result;
                    getdatas.data.forEach(element => {
                        if (element.money) {
                            element.money = (element.money / 100).toFixed(2);
                        }
                        if (element.agent_money) {
                            element.agent_money = (element.agent_money / 100).toFixed(2);
                        }
                        if (element.shop_money) {
                            element.shop_money = (element.shop_money / 100).toFixed(2);
                        }
                    });
                    this.myaxiosfunc(getdatas);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },

        // 弹窗-反显
        istruedialogEvent(data) {
            this.dialogVisible = data;
        },

        // 添加 || 编辑
        tanEvent(data, index) {
            if (data == 'add') {
                this.deploy = {
                    title: '添加',
                    search: {
                        label: '代理商:',
                        placeholder: '请输入代理商名称/手机号',
                        axiosurl: '/admin/printWallet/clientOrShopInfo',
                        type: '1',
                        setchart: { 'label': 'name', 'value': 'id' },
                        style: { 'width': '300px' },
                        disabled: false,
                    },
                    addbutton: '添加充值会员',
                }
                this.flag = index;
                this.type = 2;
                this.dialogVisible = true;
            } else {
                console.log('11111111111111111',index)
                this.deploy = {
                    title: '编辑',
                    search: {
                        label: '代理商:',
                        placeholder: '请输入代理商名称/手机号',
                        axiosurl: '/admin/printWallet/clientOrShopInfo',
                        type: '1',
                        setchart: { 'label': 'name', 'value': 'id' },
                        style: { 'width': '300px' },
                        disabled: true,
                    },
                    addbutton: '添加充值会员',
                }
                this.flag = {type:2 , agent_id: index[0].agent_id, agent_name: index[0].shop_name };
                this.type = 2;
                this.dialogVisible = true;
            }
        },

        // 删除
        delClick(data) {
            ElMessageBox.confirm("确定删除吗？", "提示：", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "primary",
            })
                .then(() => {
                    var arrdata = data.map(item=>{return item.id});
                    axios
                        .post(
                            "/admin/printWallet/setDelete",
                            {
                                id: arrdata.join(','),
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push({ path: '/rechargememberset', query: { activename: 'two' } }).catch((error) => error);
                                        this.reload();
                                    },
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "已取消",
                    });
                });
        },

        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/admin/printWallet/setIndex",
                    {
                        type: 2,
                        content: this.pagesdatas.content,
                        page: 1,
                        page_size: val,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let getdatas = response.data.result;
                        getdatas.data.forEach(element => {
                            if (element.money) {
                                element.money = (element.money / 100).toFixed(2);
                            }
                            if (element.agent_money) {
                                element.agent_money = (element.agent_money / 100).toFixed(2);
                            }
                            if (element.shop_money) {
                                element.shop_money = (element.shop_money / 100).toFixed(2);
                            }
                        });
                        this.myaxiosfunc(getdatas);
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/admin/printWallet/setIndex",
                    {
                        type: 2,
                        content: this.pagesdatas.content,
                        page: val,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let getdatas = response.data.result;
                        getdatas.data.forEach(element => {
                            if (element.money) {
                                element.money = (element.money / 100).toFixed(2);
                            }
                            if (element.agent_money) {
                                element.agent_money = (element.agent_money / 100).toFixed(2);
                            }
                            if (element.shop_money) {
                                element.shop_money = (element.shop_money / 100).toFixed(2);
                            }
                        });
                        this.myaxiosfunc(getdatas);
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },

        // 代理商查询
        getSeachinfoEvent(data) {
            //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            this.pagesdatas = data;
            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/admin/printWallet/setIndex",
                    {
                        type: 2,
                        content: data.content,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let getdatas = response.data.result;
                        getdatas.data.forEach(element => {
                            if (element.money) {
                                element.money = (element.money / 100).toFixed(2);
                            }
                            if (element.agent_money) {
                                element.agent_money = (element.agent_money / 100).toFixed(2);
                            }
                            if (element.shop_money) {
                                element.shop_money = (element.shop_money / 100).toFixed(2);
                            }
                        });
                        this.myaxiosfunc(getdatas);
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },

        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            let map = this.$utils.mapToArray(this.$utils.arrayToMap(newdata, 'agent_id'));
            this.tableData = map;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },

        // 获取列表信息
        getInfoEvent() {
            axios
                .post(
                    "/admin/printWallet/setIndex",
                    {
                        type: 2,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let getdatas = response.data.result;
                        getdatas.data.forEach(element => {
                            if (element.money) {
                                element.money = (element.money / 100).toFixed(2);
                            }
                            if (element.agent_money) {
                                element.agent_money = (element.agent_money / 100).toFixed(2);
                            }
                            if (element.shop_money) {
                                element.shop_money = (element.shop_money / 100).toFixed(2);
                            }
                        });
                        this.myaxiosfunc(getdatas);
                    }
                })
                .catch(function (error) {
                    ElMessage({
                        type: "warning",
                        message: error,
                    });
                });
        }

    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            this.getInfoEvent()
        }
    },
};
</script>
  
<style scoped>
.box-body {
    font-size: 14px;
}

.zhuyi-box {
    margin: 0 0 20px 0;
    color: var(--el-color-warning);
}

.table-box {
    margin: 20px 0;
}

.end-botton {
    text-align: center;
    margin: 20px 0;
}

.kong-class {
    margin: 20px 0;
}


.button-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.input-box {
    border: 1px solid var(--el-border-color-base);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 13px;
    color: #333333;
    margin: 10px 0;
    min-height: 40px;
}
</style>