<template>
    <div class="box-body">
        <div class="zhuyi-box">
            <span>* 注意：【默认设置】分佣规则只针对一级代理商。</span>
        </div>
        <!-- <div>
            <el-button type="primary" size="large" plain style="width:140px" @click="addEvent">新增</el-button>
        </div> -->
        <div class="table-box">
            <el-form ref="tableData" :model="tableData" :rules="ruleData">
                <el-form-item>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :data="tableData"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="index" width="65" label="序号" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :min-width="mytabletitle.minwidth"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="mytabletitle.prop === 'client_price' || mytabletitle.prop === 'less' || mytabletitle.prop === 'greater' || mytabletitle.prop === 'apply_type'"
                            >
                                <div v-if="this.tableData.length > 0">
                                    <!-- 设备价格 -->
                                    <div v-if="mytabletitle.prop === 'client_price'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.client_price'" :rules="ruleData.client_price">
                                            <el-input v-model="scope.row.client_price" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 小于 -->
                                    <div v-if="mytabletitle.prop === 'less'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.less'" :rules="ruleData.less">
                                            <el-input v-model="scope.row.less" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 大于 -->
                                    <div v-if="mytabletitle.prop === 'greater'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.greater'" :rules="ruleData.greater">
                                            <el-input v-model="scope.row.greater" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 结算方式 -->
                                    <div v-if="mytabletitle.prop === 'apply_type'">
                                        <span>T+1 结算</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <!-- <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button type="danger" size="mini" @click="delClick(scope.$index)">删除</el-button>
                                </div>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="end-botton" v-if="this.tableData.length > 0">
            <el-button type="primary" size="large" style="width: 200px" :disabled="buttondisable" @click="seveEvent('tableData')">保存</el-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'publicNumDefaultSet',
    inject: ['reload'], //刷新引用
    data() {
        // 金额效验（可小数）
        var checkInputEvent = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }

            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入数字'))
                } else {
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        let getdata = value.toString().split('.')
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error('请保留小数点后两位'))
                        }
                    }

                    if (value < 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }
        return {
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreehandle: 80,
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'client_price',
                    label: '设备价格(元)',
                    minwidth: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'less',
                    label: '收益小于设备价格时 \n 关注价格(元/次)',
                    minwidth: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'greater',
                    label: '收益大于等于设备价格时 \n 关注价格(元/次)',
                    minwidth: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'apply_type',
                    label: '结算方式',
                    minwidth: '150'
                }
            ], // 数据列表中-数据表头
            tableData: [],
            ruleData: {
                client_price: [
                    {
                        required: true,
                        message: '设备价格不能为空',
                        trigger: 'change'
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'change'
                    }
                ],
                less: [
                    {
                        required: true,
                        message: '关注价格不能为空',
                        trigger: 'change'
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'change'
                    }
                ],
                greater: [
                    {
                        required: true,
                        message: '关注价格不能为空',
                        trigger: 'change'
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'change'
                    }
                ]
            },

            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            buttondisable: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        }
    },
    methods: {
        // // 新增
        // addEvent() {
        //     let data = {
        //         type: 1,
        //         name: '',
        //         money: '',
        //         agent_money: '',
        //         shop_money: '',
        //         remark: '',
        //         agent_apply_type: '',
        //         shop_apply_type: ''
        //     }
        //     this.tableData.push(data)
        // },

        // // 删除
        // delClick(index) {
        //     this.tableData.splice(index, 1)
        // },

        // 保存
        seveEvent(formName) {
            this.buttondisable = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let tabledata = this.tableData.map((element) => {
                        if (element.less) {
                            element.less = element.less * 100
                        }
                        if (element.greater) {
                            element.greater = element.greater * 100
                        }
                        if (element.client_price) {
                            element.client_price = element.client_price * 100
                        }

                        return element
                    })

                    axios
                        .post(
                            '/admin/Admin/accountSet',
                            {
                                contents: tabledata[0]
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.buttondisable = false
                                        this.reload() //刷新
                                    }
                                })
                            } else {
                                setTimeout(() => {
                                    this.buttondisable = false
                                    this.reload() //刷新
                                }, 1000)
                            }
                        })
                        .catch(function (error) {
                            ElMessage({
                                type: 'warning',
                                message: error,
                                duration: 1000,
                                onClose: () => {
                                    this.buttondisable = false
                                    this.reload() //刷新
                                }
                            })
                        })
                } else {
                    this.buttondisable = false
                    return false
                }
            })
        },

        // 获取列表信息
        getInfoEvent() {
            axios
                .get('/admin/Admin/accountSet', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        let resdata = response.data.result
                        let getdata = response.data.result
                        getdata.less = (resdata.less / 100).toFixed(2)
                        getdata.greater = (resdata.greater / 100).toFixed(2)
                        getdata.client_price = (resdata.client_price / 100).toFixed(2)
                        this.tableData.push(getdata)
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.getInfoEvent()
        }
    },
    components: {}
}
</script>

<style scoped>
.box-body {
    font-size: 14px;
}

.zhuyi-box {
    margin: 0 0 20px 0;
    color: var(--el-color-warning);
}

.table-box {
    margin: 20px 0;
}

.end-botton {
    text-align: center;
    margin: 20px 0;
}

.kong-class {
    margin: 20px 0;
}
</style>
