<template>
    <div>
        <!-- 金融产品-合同终止审批-审核处理 -->
        <el-dialog v-model="dialogVisible" :title="deploy.title" :width="this.searchwidth"
            :before-close="handleCloseSenior">
            <div class="box-top">
                <div class="search-box">
                    <div>
                        {{ deploy.search.label }}
                    </div>
                    <div>
                        <selectFullBox ref="selectfullbox" :ModelValue="this.modelvalue"
                            :Placeholder="deploy.search.placeholder" :AxiosUrl="deploy.search.axiosurl"
                            :Type="deploy.search.type" :SetChart="deploy.search.setchart" :Style="deploy.search.style"
                            :Disabled="deploy.search.disabled"
                            v-on:changeValue="(data) => changeValueEvent(data, this.type)" />
                    </div>
                </div>
                <el-form ref="ruleForm" :model="tableData" :rules="ruleData" label-width="0px">
                    <el-divider />
                    <el-form-item>
                        <el-button type="primary" size="small" plain style="width:120px" @click="addEvent">
                            {{ deploy.addbutton }}
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <!--table表单-->
                        <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable" :data="tableData"
                            style="width: 100%" min-height="900" border v-loading="loading"
                            element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                            @cell-click="handleOneData">
                            <!--多选框-用于用户选择性导出数据-->
                            <el-table-column type="index" width="65" label="序号" />
                            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                                :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                                :width="mytabletitle.width" :min-width="mytabletitle.minwidth">
                                <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                                <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                                <template #default="scope" v-if="
                                    mytabletitle.prop === 'name' ||
                                    mytabletitle.prop === 'money' ||
                                    mytabletitle.prop === 'agent_money' ||
                                    mytabletitle.prop === 'shop_money' ||
                                    mytabletitle.prop === 'remark' ||
                                    mytabletitle.prop === 'agent_apply_type' ||
                                    mytabletitle.prop === 'shop_apply_type'
                                ">
                                    <!-- 名称 -->
                                    <div v-if="mytabletitle.prop === 'name'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.name'" :rules="ruleData.name">
                                            <el-input v-model="scope.row.name" placeholder="请输入..." clearable
                                                maxlength="50" />
                                        </el-form-item>
                                    </div>
                                    <!-- 充值金额 -->
                                    <div v-if="mytabletitle.prop === 'money'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.money'" :rules="ruleData.money">
                                            <el-input v-model="scope.row.money" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 代理商分佣金额 -->
                                    <div v-if="mytabletitle.prop === 'agent_money'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.agent_money'"
                                            :rules="ruleData.agent_money">
                                            <el-input v-model="scope.row.agent_money" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 商户分佣金额 -->
                                    <div v-if="mytabletitle.prop === 'shop_money'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.shop_money'" :rules="ruleData.shop_money">
                                            <el-input v-model="scope.row.shop_money" placeholder="请输入..." clearable />
                                        </el-form-item>
                                    </div>
                                    <!-- 赠送礼品 -->
                                    <div v-if="mytabletitle.prop === 'remark'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.remark'" :rules="ruleData.remark">
                                            <el-input v-model="scope.row.remark" placeholder="请输入..." clearable
                                                maxlength="200" type="textarea" :autosize="{ minRows: 1.3 }" />
                                        </el-form-item>
                                    </div>
                                    <!-- 代理商结算方式 -->
                                    <div v-if="mytabletitle.prop === 'agent_apply_type'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.agent_apply_type'"
                                            :rules="ruleData.agent_apply_type">
                                            <el-select v-model="scope.row.agent_apply_type" placeholder="请选择">
                                                <el-option label="T+1 结算" :value="1"></el-option>
                                                <el-option label="定期结算" :value="2"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <!-- 商户结算方式 -->
                                    <div v-if="mytabletitle.prop === 'shop_apply_type'" class="kong-class">
                                        <el-form-item :prop="scope.$index + '.shop_apply_type'"
                                            :rules="ruleData.shop_apply_type">
                                            <el-select v-model="scope.row.shop_apply_type" placeholder="请选择">
                                                <el-option label="T+1 结算" :value="1"></el-option>
                                                <el-option label="定期结算" :value="2"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </template>
                            </el-table-column>
                            <!--操作&按钮-->
                            <el-table-column :fixed="false" label="操作" :width="mainthreehandle"
                                v-if="mainthreeisshowope" prop="myoperation" id="operation">
                                <template #default="scope">
                                    <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                    <!--操作-合同列表-->
                                    <div>
                                        <el-button type="danger" size="mini" @click="delClick(scope.$index)">删除
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" @click="submitForm('ruleForm',this.type,this.flag)"
                            :disabled="mydisabled" style="margin-top: 20px;width: 120px;">提交</el-button>
                    </el-form-item>

                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { ElMessage } from "element-plus";

export default {
    name: "tanMemberAddEdit",
    inject: ["reload"], //刷新引用
    props: ['DialogVisible', 'Deploy', 'Flag', 'Type'],
    data() {
        // 金额效验（可小数）
        var checkInputEvent = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }

            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error("请输入数字"));
                } else {
                    let result = value.toString().indexOf(".");
                    if (result != -1) {
                        let getdata = value.toString().split(".");
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error("请保留小数点后两位"));
                        }
                    }

                    if (value < 0) {
                        callback(new Error("值需大于0"));
                    } else {
                        callback();
                    }
                }
            }, 500);
        };
        return {
            // 选中的搜索值
            changevalue: '',

            // 搜索框
            searchForm: {
                keywords: '',
            },
            // 搜索框-校验
            ruleSearch: {
                keywords: [
                    {
                        required: true,
                        message: "必填项不能为空！",
                        trigger: "blur",
                    },
                ],
            },

            // table
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreehandle: 80,
            // 数据列表中-数据表头
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: "name",
                    label: "名称",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "money",
                    label: "充值金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "agent_money",
                    label: "代理商分佣金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "shop_money",
                    label: "商户分佣金额(元)",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "remark",
                    label: "赠送礼品",
                    minwidth: "200",
                },
                {
                    fixedstatu: false,
                    prop: "agent_apply_type",
                    label: "代理商结算方式",
                    minwidth: "150",
                },
                {
                    fixedstatu: false,
                    prop: "shop_apply_type",
                    label: "商户结算方式",
                    minwidth: "150",
                },
            ],
            tableData: [],
            ruleData: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "change",
                    },
                ],
                money: [
                    {
                        required: true,
                        message: "充值金额不能为空",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: "change",
                    }
                ],
                agent_money: [
                    {
                        required: true,
                        message: "代理商分佣金额不能为空",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: "change",
                    }
                ],
                shop_money: [
                    {
                        required: true,
                        message: "商户分佣金额不能为空",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: "change",
                    }
                ],
                remark: [
                    {
                        required: true,
                        message: "赠送礼品不能为空",
                        trigger: "change",
                    },
                ],
                agent_apply_type: [
                    {
                        required: true,
                        message: "代理商结算方式不能为空",
                        trigger: "change",
                    },
                ],
                shop_apply_type: [
                    {
                        required: true,
                        message: "商户结算方式不能为空",
                        trigger: "change",
                    },
                ]
            },

            searchwidth: "1400px",
            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            flag: '',
            type: '',
            modelvalue: '',

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            mydisabled: false
        };
    },
    watch: {
        DialogVisible: {
            handler(newVal) {
                this.dialogVisible = newVal;
            },
            deep: true,
            immediate: true,
        },
        Deploy: {
            handler(newVal) {
                this.deploy = newVal;
            },
            deep: true,
            immediate: true,
        },
        Type: {
            handler(newVal) {
                this.type = newVal;
            },
            deep: true,
            immediate: true,
        },
        Flag: {
            handler(newVal) {
                this.flag = newVal;
                if (this.flag == -1) {
                    this.modelvalue = '';
                    this.tableData = [];
                    this.searchForm.keywords = '';
                } else {
                    if (this.flag['type'] == 2) {
                        this.modelvalue = this.flag['agent_name'];
                        this.changeValueEvent(this.flag['agent_id'], this.flag['type'])
                    } else {
                        this.modelvalue = this.flag['client_name'];
                        this.changeValueEvent(this.flag['client_id'], this.flag['type'])
                    }
                }
            },
            deep: true,
            immediate: true,
        },

    },
    methods: {
        // 获取select接口选中的数据-并查询
        changeValueEvent(data, type) {
            this.changevalue = data;

            if (data) {
                //加载loading
                this.loading = true;
                //通过Ajax向后台获取数据
                axios
                    .post("/admin/printWallet/setIndex",
                        {
                            type: type,
                            content: data,
                            page: 1,
                            page_size: 10000,
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem("token"),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 0) {
                            let getdatas = response.data.result;
                            getdatas.data.forEach(element => {
                                if (element.money) {
                                    element.money = (element.money / 100).toFixed(2);
                                }
                                if (element.agent_money) {
                                    element.agent_money = (element.agent_money / 100).toFixed(2);
                                }
                                if (element.shop_money) {
                                    element.shop_money = (element.shop_money / 100).toFixed(2);
                                }
                            });
                            // this.tableData.push(...getdatas.data); (累加-预制)
                            this.tableData = getdatas.data;
                            this.loading = false;
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error);
                    });
            } else {
                this.tableData = [];
            }

        },

        // 添加
        addEvent() {
            let data = {
                name: '',
                money: '',
                agent_money: '',
                shop_money: '',
                remark: '',
                agent_apply_type: '',
                shop_apply_type: '',
            };
            this.tableData.push(data);
        },

        // 删除
        delClick(index) {
            this.tableData.splice(index, 1);
        },

        // 提交表单-提交
        submitForm(formName, type, flag) {
            // 前端防抖
            this.mydisabled = true;
            var getdatas = this.$refs[formName].model;
            if (this.changevalue) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (getdatas.length > 0) {
                            getdatas.map((n) => {
                                n['type'] = type;
                                if (type == 2) {
                                    n['agent_id'] = flag == -1 ? this.changevalue : flag['agent_id'];
                                }
                                if (type == 3) {
                                    n['clientid'] = flag == -1 ? this.changevalue : flag['client_id'];
                                }
                                n['money'] = n['money'] * 100;
                                n['agent_money'] = n['agent_money'] * 100;
                                n['shop_money'] = n['shop_money'] * 100;
                                return n;
                            })
                            let axiosurl = (flag == -1 ? '/admin/printWallet/setAdd' : 'admin/printWallet/setUpdate')
                            axios
                                .post(
                                    axiosurl,
                                    {
                                        type: type,
                                        data: getdatas
                                    },
                                    {
                                        headers: {
                                            Authentication: localStorage.getItem("token"),
                                        },
                                    }
                                )
                                .then((response) => {
                                    if (response.data.code === 0) {
                                        this.dialogVisible = ref(false); //关闭弹窗
                                        ElMessage({
                                            type: "success",
                                            message: '成功！' + response.data.message,
                                            duration: 1000,
                                            onClose: () => {
                                                this.$router.push({ path: '/rechargememberset', query: { activename: type == 2 ? 'two' : 'three' } }).catch((error) => error);
                                                this.reload();
                                                this.mydisabled = false;
                                            },
                                        });
                                    } else {
                                        // 重置金额（元）
                                        getdatas.map((n) => {
                                            n['money'] = n['money'] / 100;
                                            n['agent_money'] = n['agent_money'] / 100;
                                            n['shop_money'] = n['shop_money'] / 100;
                                            return n;
                                        })

                                        this.mydisabled = false;
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    // 重置金额（元）
                                    getdatas.map((n) => {
                                        n['money'] = n['money'] / 100;
                                        n['agent_money'] = n['agent_money'] / 100;
                                        n['shop_money'] = n['shop_money'] / 100;
                                        return n;
                                    })
                                    this.mydisabled = false;
                                });

                        } else {
                            ElMessage({
                                type: "warning",
                                message: "请添加充值会员后提交！",
                            });
                            this.mydisabled = false;
                        }
                    } else {
                        this.mydisabled = false;
                        return false;
                    }
                });
            } else {
                ElMessage({
                    type: "warning",
                    message: "请先搜索选中" + (type == 2 ? "代理商" : "设备编号") + "，绑定对象！",
                });
                this.mydisabled = false;
            }
        },

        // 关闭-弹窗
        handleCloseSenior() {
            this.$refs['ruleForm'].resetFields();

            this.dialogVisible = false;
            this.$emit('istruedialog', this.dialogVisible);
        },


        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 1420) {
                this.searchwidth = this.sreenwidth * 0.8 + "px";
            } else {
                this.searchwidth = "1400px";
            }
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();
        }
    },
    components: {

    },
};
</script>
  
<style scoped>
.search-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.search-box div {
    margin-right: 20px;
}

.kong-class {
    margin: 20px 0;
}

.box-top {
    margin-top: 10px;
}

.box-top>>>.el-form-item__label {
    text-align: left;
}
</style>