//时间戳转换成yyyy-mm-dd HH:mm:ss
function formatDate(getdate) {
    if(getdate){
        var date = new Date(parseInt(getdate) * 1000)
        var YY = date.getFullYear() + '-'
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        return YY + MM + DD + ' ' + hh + mm + ss
    }else{
        return '-'
    }
   
}

// 设备-打印机状态-返回-对应中文值
function printStatusData(printstatusoption, printstatus) {
    let printstatusname = ''
    let printstatusnum = printstatusoption
    for (let j = 0; j < printstatusnum.length; j++) {
        const element = printstatusnum[j]
        if (printstatus) {
            if (element.value == printstatus) {
                if (printstatus === '') {
                    printstatusname = '数据异常'
                    return printstatusname
                } else {
                    printstatusname = element.label
                    return printstatusname
                }
            }
        } else {
            printstatusname = '数据异常'
            return printstatusname
        }
    }
    return printstatusname
}

// 插入&删除-省市区数组-（微页面-表单）
function inputdelCityCode(content, getareajson, flag) {
    content.forEach((element) => {
        if (element.flag == 'form') {
            element.formdata.forminfo.forEach((myment) => {
                if (myment.inputboxtype.type == 3) {
                    if (flag == 'input') {
                        myment.inputboxtype.options = getareajson
                    }
                    if (flag == 'del') {
                        myment.inputboxtype.options = []
                    }
                }
            })
        }
    })
    return content
}

// 简单分页-数据判断
function simplePagingJudge(that, getdata, val) {
    if (getdata.length > 0) {
        if (getdata.length == that.PageSize) {
            if (val * that.PageSize == that.totalCount) {
                that.totalCount += that.PageSize * 5
                that.lastpage = that.totalCount / that.PageSize
                return {
                    flag: 1,
                    totalCount: that.totalCount,
                    lastpage: that.lastpage
                }
            }
            if (val * that.PageSize < that.totalCount) {
                return {
                    flag: 2
                }
            }
        }
        if (getdata.length < that.PageSize) {
            that.totalCount = val * that.PageSize
            that.lastpage = that.totalCount / that.PageSize
            return {
                flag: 3,
                totalCount: that.totalCount,
                lastpage: that.lastpage
            }
        }
    } else {
        return {
            flag: 4
        }
    }
}

// 身份证信息-用*号替代
function hiddenCardId(str, frontLen = 8, endLen = 1) {
    //str：要进行隐藏的变量  frontLen: 前面需要保留几位    endLen: 后面需要保留几位
    var start = '*********'
    // 最后的返回值由三部分组成
    return str.substring(0, frontLen) + start + str.substring(str.length - endLen)
}

// 在数组对象中对相同元素值提取，注册新数组对象
function arrayToMap(data, ide) {
    //非数组或数据长度为0不处理
    if (!Array.isArray(data) || data.length == 0) {
        return {}
    }
    var map = {}
    for (var i = 0; i < data.length; i++) {
        let name = data[i][ide]

        if (name != undefined) {
            if (map[name] == undefined) {
                map[name] = []
            }
            map[name].push(data[i])
        }
    }
    return map
}

// 将对象转换成数组
function mapToArray(data) {
    if (data == undefined) {
        return []
    }
    let array = []
    for (let p in data) {
        array.push(data[p])
    }
    return array
}

// 特殊公用【订单详情&退款详情&超期退款订单提交详情等】：(优惠金额)
function moneyAndDiscountToExchange(discount_type, discount_price, mobetotal_discount, printtotal) {
    return [
        '合计：¥' + (discount_price / 100 + mobetotal_discount / 100).toFixed(2),
        discount_price != null && discount_price != 0 ? '打印抵扣优惠：¥' + (discount_price / 100).toFixed(2) : undefined,
        mobetotal_discount != null && mobetotal_discount != 0 ? '文库优惠价格：¥' + (mobetotal_discount / 100).toFixed(2) : undefined,
        1 - discount_price / printtotal != 0 && discount_type == 6 ? '打印费：' + ((1 - discount_price / printtotal) * 10).toFixed(2) + '折' : undefined
    ]
        .filter((n) => n)
        .join('\n')
}

export default {
    formatDate,
    printStatusData,
    inputdelCityCode,
    simplePagingJudge,
    hiddenCardId,
    arrayToMap,
    mapToArray,
    moneyAndDiscountToExchange
}
