<template>
    <div>
        <div>
            <span class="switch-export-title">切换账号</span>
        </div>
        <div v-if="admin_options.length > 0">
            <div v-for="(item, index) in admin_options" :key="index" @click="subBtEvent(item)">
                <div class="switch-line-hang"></div>
                <div class="switch-box-list">
                    <div class="switch-box-list-info">
                        <div style="width: 40px;height: 40px;">
                            <el-image :src="item.logo" style="width: 40px;height: 40px;vertical-align: middle;"></el-image>
                        </div>
                        <div>
                            <span>{{ item.brand_name }}</span>
                        </div>
                    </div>
                    <!-- <div class="switch-box-list-icon">
                        <i class="el-icon-check"></i>
                    </div> -->
                </div>
            </div>
        </div>
        <div v-else style="text-align: center;">
            <div class="switch-line-hang"></div>
            <div><span style="font-size: 12px; color: #999999;line-height: 32px;">暂无可切换的账号</span></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'switchAdmin',
    props: ['datas'],
    data() {
        return {
            admin_options: []
        }
    },
    methods: {
        // 选中切换的账号
        subBtEvent(data) {
            this.$router.push({ path: '/', query: { switchuser: JSON.stringify({ username: data.mobile, userid: data.id }) } }).catch((error) => error)
        },
        // 获取账号下拉-函数
        getAdminsEvent() {
            axios
                .get('/admin/Admin/switchT', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.admin_options = response.data.result
                    this.$emit('change', response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        this.getAdminsEvent()
    }
}
</script>

<style scoped>
.switch-export-title {
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
}

.switch-line-hang {
    background-color: #eaedf7;
    height: 1px;
    width: 100%;
    margin: 5px 0;
}
.switch-box-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.switch-box-list-info {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

.switch-box-list-icon {
    width: 20px;
    height: 20px;
    border: 1px solid var(--el-color-info);
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
}

.switch-box-list:hover {
    cursor: pointer;
    background-color: aliceblue;
    border-radius: 2px;
    color: var(--el-color-primary);
}
</style>
