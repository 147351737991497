const componentProperties = new Map()

// 数据版本  每次修改组件数据  需要对版本进行修改
// componentProperties.set('componentPropertiesVersion', 'V1.0.0')

// 设备-打印机状态
componentProperties.set('printstatusoption', {
    options: [
        {
            value: '',
            label: '全部'
        },
        {
            value: '1',
            label: '使用中'
        },
        {
            value: '10',
            label: '打印机异常'
        },
        {
            value: '3',
            label: '扫描仪异常'
        },
        {
            value: '4',
            label: '缺纸'
        },
        {
            value: '5',
            label: '夹纸'
        },
        {
            value: '6',
            label: '打印机脱机'
        },
        {
            value: '0',
            label: '空闲'
        },
        {
            value: '2',
            label: '未知'
        },
        {
            value: '11',
            label: '打印机未连接'
        },
        {
            value: '7',
            label: '异常'
        }
    ]
})

// 平台来源
componentProperties.set('thirdpartyoption', {
    options: [
        {
            value: '1',
            label: '微信自助版'
        },
        {
            value: '2',
            label: '支付宝自助版'
        },
        {
            value: '3',
            label: '微信企业版'
        },
        {
            value: '4',
            label: '微信家庭版'
        },
        {
            value: '5',
            label: '支付宝企业版'
        },
        {
            value: '6',
            label: '支付宝家庭版'
        },
        {
            value: '7',
            label: '微信公众号'
        },
        {
            value: '8',
            label: '支付宝生活号'
        }
    ],
    optionsall: [
        {
            value: '',
            label: '全部'
        },
        {
            value: '1',
            label: '微信自助版'
        },
        {
            value: '2',
            label: '支付宝自助版'
        },
        {
            value: '3',
            label: '微信企业版'
        },
        {
            value: '4',
            label: '微信家庭版'
        },
        {
            value: '5',
            label: '支付宝企业版'
        },
        {
            value: '6',
            label: '支付宝家庭版'
        },
        {
            value: '7',
            label: '微信公众号'
        },
        {
            value: '8',
            label: '支付宝生活号'
        }
    ]
})

// 财务相关
componentProperties.set('financeoption', {
    // 支付方式
    paytype: [
        {
            value: 1,
            label: '余额'
        },
        {
            value: 2,
            label: '微信'
        },
        {
            value: 3,
            label: '支付宝'
        },
        {
            value: 4,
            label: '信用卡'
        },
        {
            value: 5,
            label: '余额代付'
        },
        {
            value: 6,
            label: '现金'
        },
        {
            value: 7,
            label: '打印金'
        }
    ]
})

// 金融产品配置
componentProperties.set('dercontractset', {
    // 单位
    unit: [
        {
            value: 1,
            label: '天'
        },
        {
            value: 2,
            label: '周'
        },
        {
            value: 3,
            label: '月'
        }
    ]
})

// 前端直传oss-文件配置
componentProperties.set('osspostset', {
    // 文件名
    files: [
        {
            value: 1,
            label: 'qrcode',
            tips: '二维码'
        },
        {
            value: 3,
            label: 'print_source',
            tips: '文库文件'
        },
        {
            value: 4,
            label: 'print_course',
            tips: '课程文件'
        },
        {
            value: 5,
            label: 'advertiser_extend',
            tips: '广告主宣传页'
        },
        {
            value: 6,
            label: 'advertiser',
            tips: '广告主产生的PDF文件'
        },
        {
            value: 7,
            label: 'general',
            tips: '证件照'
        },
        {
            value: 8,
            label: 'general',
            tips: '常见问题图标'
        },
        {
            value: 100,
            label: 'general',
            tips: '通用'
        },
        {
            value: 101,
            label: 'general',
            tips: '设置'
        },
        {
            value: 50,
            label: 'client_advert',
            tips: '设备广告'
        },
        {
            value: 51,
            label: 'client_advert_appeal',
            tips: '设备广告申诉'
        },
        {
            value: 9,
            label: 'share_question',
            tips: '退款'
        },
        {
            value: 10,
            label: 'share_question_audits',
            tips: '退款审核'
        },
        {
            value: 11,
            label: 'widthdraw_fapiao',
            tips: '提现发票'
        },
        {
            value: 12,
            label: 'printcate/static',
            tips: '(未知)'
        },
        {
            value: 200,
            label: 'mini_footer_icon',
            tips: '小程序底部导航图标'
        },
        {
            value: 201,
            label: 'dercontract_files',
            tips: '金融产品-后台上传-合同'
        }
    ]
})

// 负责人相关
componentProperties.set('winuserinfo', {
    // 负责人类型
    type: [
        {
            value: 1,
            label: '代理'
        },
        {
            value: 2,
            label: '直营'
        }
    ]
})

export default componentProperties
