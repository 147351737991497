<template>
    <div id="mycascaders">
        <!--  @expand-change="changeEvent" -->
        <el-cascader
            v-model="modelvalue"
            :options="options"
            :props="props"
            :collapse-tags="collapse_tags"
            :placeholder="placeholder"
            :disabled="disabled"
            style="width: 100%;"
            clearable
            @change="changeEvent"
        />
    </div>
</template>

<script>
export default {
    name: 'treeCascaderBox',
    inject: ['reload'], //刷新引用
    props: ['ModelValue', 'Options', 'Placeholder', 'Props', 'Disabled', 'CollapseTags'],
    data() {
        return {
            props: {},
            modelvalue: '',
            options: [],
            placeholder: '',
            disabled: false,
            collapse_tags: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token
        }
    },
    watch: {
        ModelValue: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        },
        Options: {
            handler(newVal) {
                this.options = newVal
            },
            deep: true,
            immediate: true
        },
        Placeholder: {
            handler(newVal) {
                this.placeholder = newVal
            },
            deep: true,
            immediate: true
        },
        Props: {
            handler(newVal) {
                this.props = newVal
            },
            deep: true,
            immediate: true
        },
        Disabled: {
            handler(newVal) {
                this.disabled = newVal
            },
            deep: true,
            immediate: true
        },
        CollapseTags: {
            handler(newVal) {
                this.collapse_tags = newVal ? newVal : false
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        changeEvent(data) {
            this.$emit('changedatas', data)
        }
    },
    created() {}
}
</script>

<style scoped></style>
