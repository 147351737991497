<template>
    <div>
        <!-- 金融产品-合同终止审批-审核处理 -->
        <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                <div class="tan-box">
                    <el-form-item label="退还剩余本金：" prop="money">
                        <span>¥ </span><span>{{ this.returnmoney }}</span><span> 元</span>
                    </el-form-item>
                    <el-form-item label="选择处理方式：" prop="status">
                        <el-radio-group v-model="ruleForm.status">
                            <el-radio :label="2">驳回</el-radio>
                            <el-radio :label="1">同意退款</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="相关图片(选填):" prop="images">
                        <mainimgesup ref="myimges" v-model="ruleForm.images" :myimgesup="myimgesup"
                            :myimgesupisshow="myimgesupisshow" :filelist="ruleForm.images"
                            v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                            v-on:deluploadfile="(data) => deluploadfileEvent(data)" v-on:getfiletype="getfiletypeEvent">
                        </mainimgesup>
                    </el-form-item>
                    <el-form-item label="处理备注(仅后台可见):" prop="remark">
                        <el-input v-model="ruleForm.remark" type="textarea" maxlength="200" show-word-limit
                            :autosize="{ minRows: 2 }" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import mainimgesup from "@/components/Mainimgesup.vue";

export default {
    name: "derContractToExamine",
    inject: ["reload"], //刷新引用
    props: ['Id', 'ReturnMoney', 'DialogVisible'],
    data() {
        return {
            id: '',

            searchwidth: "580px",
            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            //弹窗中-form表单
            ruleForm: {
                id: "",
                status: 2,
                images: [],
                remark: "",
            },
            //弹窗中-form表单-必填规则
            rules: {
                status: [
                    {
                        required: true,
                        message: "处理方式为必选项",
                        trigger: "change",
                    },
                ],
                images: [
                    {
                        required: false,
                        message: "相关图片为必填项",
                        trigger: "change",
                    },
                ],
                remark: [
                    {
                        required: false,
                        message: "处理备注为必填项",
                        trigger: "change",
                    },
                ],
            },
            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow: true,
            // 上传-【处理】-上传留言图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: true, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                data: { id: '', thirdparty: '' }, // upload上传时的携带参数
                action: "/api/admin/upload/openFile", //upload上传API地址
                headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
                multiple: true, //上传多个文件
                title: "上传图片", //button-按钮文字
                title_type: "primary", //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: "支持扩展名：*.jpg、*.jpeg、*.png ; 最多上传4张图片，每张图片大小不超过5M", //提示&建议文字
                limit: "4", //上传数量限制,0表示不限
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；10-退款审核
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 5000, //文件大小（单位kb）
                        type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
                        typechart: "jpg/jpeg/png", //文件格式提示规则
                    },
                },
            },

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            mydisabled: false
        };
    },
    watch: {
        Id: {
            handler(newVal) {
                this.id = newVal;
            },
            deep: true,
            immediate: true,
        },
        ReturnMoney: {
            handler(newVal) {
                this.returnmoney = newVal;
            },
            deep: true,
            immediate: true,
        },
        DialogVisible: {
            handler(newVal) {
                this.dialogVisible = newVal;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        // 弹出框[处理-按钮]-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // console.log("当前获取的回传数据：" + JSON.stringify(data));
            let onedata = {
                name: data.name,
                url: data.url,
            };
            this.ruleForm.images.push(onedata);
        },

        //弹出框[处理-按钮]-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            // console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
            if (data.length > 0) {
                let dataarray = [];
                data.forEach((element) => {
                    let onedata = {
                        name: element.name,
                        url: element.url,
                    };
                    dataarray.push(onedata);
                });
                this.ruleForm.images = dataarray;
            } else {
                this.ruleForm.images = [];
            }
        },

        //弹出框-提交表单-确认
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true;

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model;
                    // console.log('getdatas:',getdatas)
                    // console.log(getdatas.images.map((n) => {return n.url}) )
                    axios
                        .post(
                            "/admin/Lease/audit",
                            {
                                id: this.id,
                                status: getdatas.status,
                                iamges: getdatas.images.map((n) => { return n.url }).join(','),
                                remark: getdatas.remark,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false); //关闭弹窗
                                ElMessage({
                                    type: "success",
                                    message: '处理成功！' + response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload(); //刷新
                                        this.mydisabled = false;
                                    },
                                });
                            } else {
                                this.mydisabled = false;
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.mydisabled = false;
                        });
                } else {
                    console.log("error submit!!");
                    this.mydisabled = false;
                    return false;
                }
            });
        },

        // 取消-弹窗
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        // 关闭-弹窗
        handleCloseSenior() {
            this.$refs['ruleForm'].resetFields();
            this.dialogVisible = false;
            this.$emit('istruedialog', this.dialogVisible);
        },


        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + "px";
            } else {
                this.searchwidth = "580px";
            }
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();
        }
    },
    components: {
        mainimgesup,
    },
};
</script>
  
<style scoped>

</style>